import { render, staticRenderFns } from "./FormClaim.vue?vue&type=template&id=7897e984&scoped=true&"
import script from "./FormClaim.vue?vue&type=script&lang=js&"
export * from "./FormClaim.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7897e984",
  null
  
)

export default component.exports
<template>
	<section class="content">
		<div class="modal show" tabindex="-1" role="dialog" ref="formClaim" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="submitClaim">
						<div class="modal-header">
							<h5 class="modal-title">Ajukan Klaim {{ special ? 'Khusus' : '' }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="alert alert-danger" v-if="message.length">{{message}}</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Kategori</label>
									<v-select :options="categories" :reduce="(opt) => opt.code" v-model="form.category"
										@input="selectCategory"></v-select>
								</div>
								<div class="col-md-12 form-group" v-if="form.category == 6">
									<label class="control-label">Judul</label>
									<input type="text" maxlength="20" class="form-control" v-model="form.title"
										required="required" />
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<input type="file" accept="image/*" name="userfile[]" id="userfile" ref="userfile"
										multiple style="display: none" @change="uploadFiles" />
									<button type="button" @click="showUpload" class="btn-xs btn btn-secondary">
										<i class="fa fa-paperclip"></i> Lampiran
									</button>
									<ul class="pt-2">
										<li v-for="file in files">
											{{ file.name }}
											<span>
												<div class="spinner-border spinner-border-sm" role="status"
													v-if="!file.status">
													<span class="sr-only">Loading...</span>
												</div>
												<button v-if="file.status" :data-id="file.code"
													@click="removeFile(file.code)" type="button" class="btn btn-xs">
													<i class="fa fa-times"></i>
												</button>
											</span>
										</li>
									</ul>
								</div>
								<div class="col-md-6 text-right">
									<button type="submit" class="btn btn-primary">Laporkan</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal show" tabindex="-1" role="dialog" ref="formUpload" data-backdrop="static">
			<div class="modal-dialog modal-sm" role="document">
				<div class="modal-content">
					<form role="form">
						<div class="modal-body">upload</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "vue-select/dist/vue-select.css";
export default {
	name: "FormClaim",
	props: {
		show: Boolean,
		value: Array,
		orderId: String,
        status: Number,
		special: Boolean,
		courier_id: Number
	},
	data() {
		return {
			orderOptions: [],
			errors: [],
			categories: [],
			priorityOpt: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			addrJne: [],
			courierBranchOption: [],
			formTitle: "Buat Tiket",
			files: [],
			loaded: false,
			message: "",
			form: {
				//to create pickup address
				id: "",
				items: [],
				category: "",
				attachments: ""
			}
		};
	},
	components: {
		vSelect,
		maskedInput,
		CurrencyInput
	},
	created() {},
	methods: {
		submitClaim: function () {
			Swal.fire({
				title: "Konfirmasi",
				text: "Anda Yakin Ingin " + (this.special ? "Special" : "") + " Claim Paket ?",
				showCancelButton: true,
				confirmButtonText: `Lanjutkan`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.submitForm();
				}
			});
		},
		submitForm: function (ev) {
			var items = [];
			/*if(this.files.length == 0)
	  {
		Swal.fire("Pilih lampiran", "Lampiran tidak boleh kosong", "error");
		return false;
	  }*/
			for (var i in this.files) {
				items.push(this.files[i].code);
			}
			if (!this.form.category) return;
			var data = {
				orders: this.value,
				attachments: items,
				category: this.form.category,
				title: this.form.title
			};
			authFetch("/ticket/claim" + (this.special ? "/special" : ""), {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				})
				.then(res => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then(js => {
					if (js.success) {
						this.form = {};
						this.files = [];
						this.$emit("change");
						$(this.$refs.formClaim).modal("hide");
						Swal.fire({
							title: "Claim Berhasil",
							icon: "success",
							html: js.message,
							showCloseButton: true,
						});
					} else {
						this.message = js.message;
						Swal.fire("Proses gagal", `${js.message}`, "error");
					}
				});
		},
		selectCategory: function (v) {
			let cat = this.categories.filter(function (arr, i) {
				return arr.code == v;
			});
			if (cat[0].code != 6) this.form.title = cat[0].label;
			else this.form.title = "";
		},
		showUpload: function (ev) {
			//$(this.$refs.formUpload).modal("show");
			$(this.$refs.userfile).click();
		},
		uploadFiles: function (event) {
			const e = this.$refs;
			var input = event.target;
			this.files = input.files;
			var formData = new FormData();
			for (var i in this.files) {
				formData.append("file[]", input.files[i]);
			}
			formData.append("subdir", "/ticket/" + new Date().getTime());
			authFetch("/ticket/claim/upload", {
					method: "POST",
					body: formData,
					headers: {
						"Content-Type": null
					}
				})
				.then(res => res.json())
				.then(json => {
					this.files = json;
				});
		},
		removeFile: function (code) {
			let newFiles = this.files.filter(function (arr, i) {
				return arr.code != code;
			});
			this.files = newFiles;
		},
		loadCategories: function () {
			authFetch("/ticket/claim/categories/" + this.status + '/' + this.special+ '/' + this.courier_id).then(res => {
				res.json().then(json => {
					if (json.success) {
						this.categories = json.data;
					}
				});
			});
		}
	},
	mounted() {
		// console.log("warehouse1", this.value);
		const e = this.$refs;
		// if (this.show === true) $(e.formSelectPickup).modal("show");
		// console.log(this.show);
		$(e.formClaim).on("show.bs.modal", e => {
			if (!this.loaded) {
				this.loadCategories();
				this.loaded = true;
			}
		});

		$(e.formClaim).on("hide.bs.modal", e => {
			this.message = "";
		});
	},
	watch: {
		show: function (old, val) {
			$(this.$refs.formClaim).modal("show");
		},
		orderId: function (old, val) {
			//this.form.refcode = this.orderId;
		},
		value: function (old, val) {},
		status: function (old, val) {}
	}
};
</script>
<style scoped>
</style>
<template>
	<section class="content">
		<div class="overlay-wrapper">
			<div class="card card-primary">
				<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card-body">
					<div class="row mb-2" style="row-gap: 0.5rem;">
						<div class="col-12">
							<h6 class="mb-0">Filter Pencarian</h6>
						</div>
						<div class="col-12 col-sm-auto">
							<select class="form-control" @change="dataType($event)">
								<option value="pickupTime">Dibuat Antara</option>
								<option value="updatedOn">Update Antara</option>
							</select>
						</div>
						<div class="col-12 col-sm-auto">
							<div class="input-group">
								<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
								<div class="input-group-append">
									<div class="input-group-text" style="background-color: #fff">
										<span><span class="fa fa-calendar"></span></span>
									</div>
								</div>
							</div>
						</div>
						<div class="order-1 order-sm-0 col-auto">
							<button class="btn dropdown-icon dropdown-toggle" v-bind:class="btnFilter"
								@click="selectStatus">Status </button>
						</div>
						<div class="order-0 order-sm-1 col-12 col-sm-auto">
							<select class="form-control" @change="orderType">
								<option value="">Tipe Order</option>
								<option value="0">Drop</option>
								<option value="1">Pickup</option>
							</select>
						</div>
						<div class="order-2 col-auto ml-auto">
							<div class="btn-group" ref="toolbar">
								<button type="button" data-action="read" class="btn btn-default"><i
										class="fa fa-sync-alt"></i></button>
								<button type="button" :disabled="downloading" v-on:click="downloadXlsx"
									data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> Download</button>
							</div>
						</div>
					</div>
					<div class="table-responsivex">
						<!-- <div class="btn-area" ref="btnSelected" style="display:none;">
							<button type="button" class="btn btn-info" @click="startPrint"><i class="fa fa-print"></i>
								Resi</button>
							<button type="button" class="ml-2 btn btn-info" @click="startPrintInvoice"><i class=" fa fa-print"></i>
								Invoice</button>
							<button type="button" v-if="canDelete() && filter.canc" class="ml-2 btn btn-secondary"
								@click="uncancelOrder"  :hidden="courier !== 'JNE'" :disabled="deleting"><i class="fas fa-trash-restore-alt"></i> Uncancel</button>
							<button type="button" v-if="filter.rpick || filter.draft || filter.err || canDelete()" class="ml-2 btn btn-danger"
								@click="cancelOrder" :disabled="deleting"><i class="fa fa-times"></i> Delete</button>
							<button type="button" v-if="filter.retok" class="ml-2 btn btn-success"
								@click="confirmAcceptReturn" :disabled="deleting"><i class="fa fa-check"></i>
								Terima</button>
							<button type="button" v-if="filter.retok || filter.delok || filter.pbreach || filter.delgo || filter.deler || filter.retgo" class="ml-2 btn btn-danger" @click="claimReturn"
								:disabled="deleting"><i class="fa fa-exclamation-circle"></i> Claim</button>
							<button type="button" v-if="filter.retclm && this.userInfo.role_id<9" class="ml-2 btn btn-success" @click="closeClaimReturn"
								:disabled="deleting">Proses</button>

						</div> -->
						<div class="table-responsivex">
							<table class="table table-hover" ref="tableuser" id="tblriwayat" width="100%">
								<thead>
									<tr>
										<th>No. Order</th>
										<th>No. Resi</th>
										<th>Pengirim</th>
										<th>Customer</th>
										<th>Tujuan</th>
										<th>Kurir</th>
										<th>Tanggal</th>
										<th>Status</th>
										<th>Ticket</th>
										<th>ID Shipper</th>
										<th>Shipper</th>
										<th>Service</th>
										<!-- {{ this.userInfo.role_id == 1 || this.userInfo.role_id == 2 || this.userInfo.role_id == 3 || this.userInfo.role_id == 10?<th>Mark</th>:<th>Tipe</th> }} -->
										<th>{{ this.userInfo.role_id == 1 || this.userInfo.role_id == 2 || this.userInfo.role_id == 3 || this.userInfo.role_id == 10 || this.userInfo.role_id == 6?'Mark':'Tipe' }}</th>
									</tr>
								</thead>
								<tbody @click="handleClick">
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<p v-if="errors.length" class="alert alert-danger">
								<b>Please correct the following error(s):</b>
								<ul>
									<!-- <li v-for="error in errors">{{ error }}</li> -->
								</ul>
							</p>
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Username</label>
									<input type="hidden" name="row_id" value="" />
									<input id="username" class="form-control" v-model="form.username" type="text"
										required="required" name="username" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Group</label>
									<select v-model="form.role_id" class="form-control">
										<!-- <option v-for="role in groups" v-bind:value="role.id">{{ role.label }}</option> -->
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Display Name</label>
									<input id="name" class="form-control" v-model="form.display_name" type="text"
										required="required" name="i_name" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Email</label>
									<input id="email" class="form-control" v-model="form.email" type="text"
										required="required" name="i_email" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Password</label>
									<input id="pwd" class="form-control" value="" type="password" name="i_pwd" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label"> </label>
									<label class="checkbox control-label">
										<input value="1" checked="checked" type="checkbox" name="active" />
										Active</label>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">Save changes</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<Historyfilter :show="show" v-model="filter" v-on:change="updateFilter" />
		<FormClaim :show="openClaim" :status="trackingStatus" v-model="selectedItems" v-on:change="reloadData" />
		<FormClosingClaim :show="showClosingClaim" v-model="selectedItems" v-on:change="reloadData" />
		<div class="modal" tabindex="-1" role="dialog" ref="formPrint" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form">
						<div class="modal-header">
							<h5 class="modal-title" id="title_saldo">Cetak Resi</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-6">
									<div class="info-box2 mb-3 clearfix">
										<span class="info-box-icon float-left"
											style="width: 50px; height: 50px; font-size: 1.875rem"><i
												class="fas fa-file-pdf fa-w-12"></i></span>

										<div class="info-box-content">
											<div class="info-box-text">File PDF</div>
											<div class="info-box-number">
												{{ totalItems }} Pesanan {{ totalPages }} Halaman
											</div>
										</div>
										<!-- /.info-box-content -->
									</div>
								</div>
								<div class="col-6 text-right">
									<button ref="elDownload" type="button" @click="printLabel" :disabled="downloading"
										class="btn btn-primary">
										{{ printBtnMessage }}
									</button>
								</div>
							</div>
						</div>
						<div class="modal-footer pl-0">
							<div class="row" style="width: 100%; align-items: center">
								<div class="col-6">Template</div>
								<div class="col-6 text-right">
									<button v-if="false" type="button" class="btn btn-secondary" data-dismiss="modal">
										Tutup
									</button>
									<select class="form-control" v-model="templateOpt" @change="templateChange($event)">
										<option value="1">1 Label per Halaman</option>
										<option value="2">2 Label per Halaman</option>
										<option value="4">4 Label per Halaman</option>
										<option value="5">10cm x 8.5cm</option>
										<option value="6">10cm x 10cm</option>
										<option value="9">10cm x 15cm</option>
										<option value="10">7,5cm x 6,5cm</option>
									</select>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="formPrintInvoice" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form">
						<div class="modal-header">
							<h5 class="modal-title" id="title_saldo">Cetak Invoice</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-6">
									<div class="info-box2 mb-3 clearfix">
										<span class="info-box-icon float-left"
											style="width: 50px; height: 50px; font-size: 1.875rem"><i
												class="fas fa-file-pdf fa-w-12"></i></span>

										<div class="info-box-content">
											<div class="info-box-text">File PDF</div>
											<div class="info-box-number">
												{{ totalItems }} Pesanan {{ totalPages }} Halaman
											</div>
										</div>
										<!-- /.info-box-content -->
									</div>
								</div>
								<div class="col-6 text-right">
									<button ref="elDownload" type="button" @click="printLabel" :disabled="downloading"
										class="btn btn-primary">
										{{ printBtnMessage }}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal fade"  tabindex="-1" role="dialog" ref="formCancelOrder" data-backdrop="static">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
				<div class="modal-body m-3" v-if="!successBatal">
					<h4 class="text-center font-weight-bold">Alasan Pembatalan</h4>
					<form role="form" @submit="deleteOrder($event)" ref="formDeleteReason">
						<div class="form-group h6">
							<label v-for="(template, index) in templateBatal" class="d-flex align-items-center font-weight-normal" style="gap: 5px">
								<input type="radio" name="delete_reason" :value="template" @change="textBatalChange(index)" v-model="textBatal" /> {{template}}
							</label>
						</div>
						<div class="form-group" v-if="customBatal">
							<label>Ceritakan Alasanmu</label>
							<textarea class="form-control" placeholder="Tuliskan alasan pembatalan" v-model="textBatal"></textarea>
							<small class="d-block text-right mt-1" :class="'text' + colorBatal">Minimal 25 karakter</small>
						</div>
						<div class="d-flex justify-content-between">
							<button type="reset" class="btn btn-lg btn-outline-primary w-49" data-dismiss="modal">Batal</button>
							<button type="submit" class="btn btn-lg btn-primary w-49" :disabled="deleting">Lanjutkan</button>
						</div>
					</form>
				</div>
				<div class="modal-body m-3" v-if="successBatal">
					<img src="../assets/img/cancel_order.svg" class="d-block m-auto" />
					<h5 class="text-center font-weight-bold">Pengajuan pembatalan pesanan berhasil dibuat</h5>
					<div class="text-center">Pengajuan pembatalan pesanan kamu berhasil dibuat.<br />Kami tunggu pesananmu selanjutnya ya!</div>
					<div>&nbsp;</div>
					<button class="btn btn-block btn-lg btn-primary" data-dismiss="modal">Kembali ke riwayat kiriman</button>
				</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, openChat, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import FormClaim from "@/dialog/FormClaim";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import moment from "moment";
import "icheck-material";
export default {
	name: "Arsip",
	data() {
		return {
			dateStart: '',
			dateEnd: '',
			errors: [],
			method: "",
			roles: "",
			groups: [],
			formTitle: "Tambah User",
			form: {
				name: "",
				username: "",
				email: "",
			},
			groupId: '',
			downloading: false,
			show: false,
			filter: {
				dt1: moment('2021-01-01'),
				dt2: moment().add(2, 'd'),
			},
			data_type: "pickupTime",
			dt1: moment('2021-01-01'),
			dt2: moment().add(2, 'd'),
			selectedCount: 0,
			totalItems: 0,
			totalPages: 0,
			isOpen: false,
			printBtnMessage: "",
			courier: [],
			urlDownload: "",
			downloading: false,
			templateOpt: 5,
			selectedItems: [],
			selectedResi: '',
			btnFilter: 'btn-default',
			deleting: false,
			processing: false,
			openClaim: false,
			showClosingClaim: false,
			waLink: '',
			loadingContent: false,
			trackingStatus: 0,
			textBatal: '',
			templateBatal: [
				'Alamat pengiriman saya salah',
				'Alamat penjemputan saya salah',
				'Detail pesanan saya salah',
				'Kurang cocok dengan ongkos kirimnya',
				'Ingin mengganti kurir pengiriman',
				'Indikasi fraud',
				'Alasan lainnya',
			],
			customBatal: false,
			colorBatal: 'dark',
			successBatal: false,
		};
	},
	watch: {
		$route(to, from) {
			if (to.path === "/riwayat/all") {
				sessionStorage.filterData = '';
				sessionStorage.filterStatus = '';
				this.btnFilter = 'btn-default';
				this.filter = {};
				this.table.api().ajax.reload();
				$('.loading-overlay').removeClass('show');
			}
		},
		textBatal: function (val) {
			this.textBatal = charsRemover(val);
		},
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()

			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	components: {
		Historyfilter,
		FormClaim,
		FormClosingClaim
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		if (this.$route.path === "/riwayat/all") {
			sessionStorage.filterData = '';
			sessionStorage.filterStatus = '';
		}
		if (this.$route.params.filter)
			this.filter = this.$route.params.filter;
		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		console.log("popo", this.filter.dt1);
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		/*
		  dt1
		  dt2
		  draft
		  rpick
		  delgo
		  delok
		  deler
		  recon
		  npaid
		  error
		  sla1
		  sla5
		  sla10
		  retgo
		  retok
		  retacc
		  retclm

		  gid
			d.ts6 = self.$route.params.draft ? 1 : 0;
			d.ts7 = self.$route.params.pickup ? 1 : 0;
			d.ts8 = self.$route.params.delivery ? 1 : 0;
			d.ts9 = self.$route.params.delivered ? 1 : 0;
			d.ts10 = self.$route.params.rdk ? 1 : 0;
			d.ts11 = self.$route.params.rtk ? 1 : 0;
			d.ts12 = self.$route.params.returned ? 1 : 0;
			d.ts13 = self.$route.params.notmove ? 1 : 0;
			d.ts14 = self.$route.params.open ? 1 : 0;
			d.ts15 = self.$route.params.closed ? 1 : 0;
			d.recon = self.$route.params.recon ? 1 : 0;
			*/
		if (Object.keys(this.filter).length < 1) {
			if (sessionStorage.filterData) this.filter = JSON.parse(sessionStorage.filterData);
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;
		this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
		this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

		if (sessionStorage.filterStatus == 1) {
			this.btnFilter = 'btn-info';
		}
		
		authFetch("/users/groups")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.groups = js.data;
			});
	},
	methods: {
		canDelete: function(){
			if(this.userInfo.role_id == 1 || this.userInfo.role_id == 2)
				return true;
			return false;
		},
		confirmAcceptReturn: function () {
			Swal.fire({
				title: "Konfirmasi",
				text: "Pastikan retur pesanan ini sudah diterima ?",
				showCancelButton: true,
				confirmButtonText: `Terima Retur`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.acceptReturn();
				}
			});
		},
		closeClaimReturn: function () {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			//this.openClaim = true;
			this.showClosingClaim = !this.showClosingClaim;
		},
		acceptReturn: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.processing = true;
			authFetch(
					"/order/return_accept", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(selected)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.processing = false;
					this.table.api().ajax.reload();
				});
		},
		claimReturn: function () {
			if (this.filter.delgo>0) this.trackingStatus = 1;
			else if (this.filter.delok>0) this.trackingStatus = 2;
			else if (this.filter.retgo>0) this.trackingStatus = 3;
			else if (this.filter.retok>0) this.trackingStatus = 4;
			else if (this.filter.deler>0) this.trackingStatus = 5;
			else if (this.filter.pbreach>0) this.trackingStatus = 6;

			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			//this.openClaim = true;
			this.openClaim = !this.openClaim;
		},
		infoClaim(claim){
			var statusClaim = '';
            if (claim == 1) {
              statusClaim = '<span class="badge badge-info">Claim diproses</span>';
            } else if (claim == 2) {
              statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
            } else if (claim == 3) {
              statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
            } else if (claim == 4) {
              statusClaim = '<span class="badge badge-warning">Claim dibanding</span>';
            } else if (claim == 5) {
              statusClaim = '<span class="badge bg-indigo">Claim void</span>';
            }
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
			var ongkir = '';

			if (track.updated == 1){
				ongkir += ' <span class="badge" style="color: #F86F15; background-color: #FFD68A"><i class="fi fi-rr-triangle-warning justify-content-center align-items-center" bold></i> Perubahan Ongkir</span>'
			}

			if (track.cancel == 1)
			{
				output += '<span class="badge badge-danger">Cancel</span>';
			}			
			else if (track.error_message)
			{
				output += '<span class="badge badge-danger">Error</span>';
			}
			else if (track.pickupStatus == 0)
			{
				if (track.job_status == 1){
					output += '<span class="badge badge-inverse">Menunggu resi</span>';
				} else if (track.job_status == 2){
					output += '<span class="badge badge-inverse">Generate resi</span>';
				} else {
					// output += '<span class="badge badge-inverse">Draft</span>';
					output += '<span class="badge badge-danger">Error</span>';
				}
			}
			else if (track.orderStatus == 2)
			{
				output += '<span class="badge bg-indigo">Void</span>';
			}
			else
			{
				if (track.trackingStatus == 0)
				{
					if(track.order_type == 1){
						output += '<span class="badge badge-secondary">Menunggu Drop</span>';
					} else {
						output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
					}
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
					}
					else if (!track.retStatus)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
					}
				}
				else if (track.trackingStatus == 5) 
				{
					if (track.problem_description != null){
						output += '<span class="badge badge-danger">Bermasalah - '+track.problem_description+'</span>';
					} else {
						output += '<span class="badge badge-danger">Bermasalah</span>';
					}
				}
				output += this.infoClaim(track.retClaim);

				if (track.special_claim > 0) {
					output += '<span style="border-width: thin;border-style: solid;" class="badge border-' + (track.special_claim == 1 ? 'info text-info">Request' : track.special_claim == 2 ? 'success text-success">Approved' : track.special_claim == 3 ? 'danger text-danger">Rejected' : 'warning text-warning">Unknown') + ' Special Claim</span>';
				}
			}
			// var output = '';
			// output += this.statusTracking;
			// output += this.statusClaim;
			return output+ongkir;
		},
		cancelOrder: function () {
			this.successBatal = false;
			const swalConfirmButtons = Swal.mixin({
				customClass: {
				title: 'h4',
				actions: 'd-flex justify-content-between flex-row-reverse',
				cancelButton: 'btn btn-lg btn-outline-primary w-49 font-weight-bold',
				confirmButton: 'btn btn-lg btn-primary w-49 font-weight-bold',
				},
				buttonsStyling: false
			});
			swalConfirmButtons.fire({
				title: "Apakah kamu yakin untuk membatalkan?",
				text: "Pastikan kurir belum melakukan pickup agar paketmu bisa dibatalkan",
				showCancelButton: true,
				cancelButtonText: `Tutup`,
				confirmButtonText: `Ya, batalkan`,
			}).then((result) => {
				const r = this.$refs;
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					$(r.formCancelOrder).modal("show");
				}
			});
		},
		textBatalChange: function (index) {
			if (index == 6) {
				this.textBatal = '';
				this.customBatal = true;
			} else {
				this.textBatal = this.templateBatal[index];
				this.customBatal = false;
				this.colorBatal = 'dark';
			}
		},
		deleteOrder: function (e) {
			if (this.customBatal && this.textBatal.length < 25) {
				Swal.fire('Minimal 25 karakter');
				this.colorBatal = 'danger';
				e.preventDefault();
				return;
			} else if (this.textBatal == '') {
				Swal.fire('Pilih alasan pembatalan');
				e.preventDefault();
				return;
			}

			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked) {
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = { group: selected, items: this.textBatal };
			this.deleting = true;
			authFetch(
					"/order/cancel", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.deleting = false;
					if (json.success) {
						this.textBatal = '';
						this.customBatal = false;
						this.colorBatal = 'dark';
						this.successBatal = true;
						this.table.api().ajax.reload();
					} else {
						if (json.message) {
							Swal.fire(json.message);
						} else if (json.desc) {
							Swal.fire(json.desc);
						} else {
							Swal.fire('Gagal membatalkan pesanan');
						}
					}
				});
			e.preventDefault();
		},
		uncancelOrder: function () {
			Swal.fire({
				title: "Konfirmasi",
				text: "Uncancel Data Pesanan?",
				showCancelButton: true,
				confirmButtonText: `Lanjutkan`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.undeleteOrder();
				}
			});
		},
		undeleteOrder: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			this.deleting = true;
			authFetch(
					"/order/uncancel", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.deleting = false;
					this.table.api().ajax.reload();
				});
		},
		
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-order');
			for (var i = 0; i < checkboxes.length; i++) {
				var exp = checkboxes[i].value.split(';');
				this.courier = exp[1];
				checkboxes[i].checked = val;
			}
			if (val && checkboxes.length > 0)
				this.selectedCount = checkboxes.length;
			else this.selectedCount = 0;
			if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
			else $(this.$refs.btnSelected).hide();
		},
		downloadLabel() {
			this.printBtnMessage = "Proses...";
			localStorage.defaultTpl = this.templateOpt;
			this.downloading = true;
			if (this.templateOpt < 5) {
				var nPage = parseInt(this.totalItems / this.templateOpt);
				this.totalPages =
					this.totalItems % this.templateOpt == 0 ? nPage : nPage + 1;
			} else {
				this.totalPages = this.totalItems;
			}
			authFetch(
					"/report/cetakresi/items?template=" +
					this.templateOpt, {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					this.urlDownload = url;
					this.printBtnMessage = "Download";
				});
		},
		downloadInvoice() {
			this.printBtnMessage = "Proses...";
			localStorage.defaultTpl = this.templateOpt;
			this.downloading = true;
			if (this.templateOpt < 5) {
				var nPage = parseInt(this.totalItems / this.templateOpt);
				this.totalPages =
					this.totalItems % this.templateOpt == 0 ? nPage : nPage + 1;
			} else {
				this.totalPages = this.totalItems;
			}
			authFetch(
					"/report/cetakresi/items?template=8", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							// "Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						if (window.navigator.userAgent.match(/Mobile/i)){
							this.downloading = true;
						} else {
							this.downloading = false;
						}
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					this.urlDownload = url;
					this.printBtnMessage = "Download";
					//this.printLabel();
				});
		},
		printLabel() {
			var a = document.createElement("a");
			// a.href = this.urlDownload;

			if (window.navigator.userAgent.match(/Mobile/i)){
				a.href = window.open(this.urlDownload);
			} else {
				a.href = this.urlDownload;
			}
			a.download = auth.user().display_name + "_" + this.selectedResi + ".pdf";
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove();
		},
		templateChange(e) {
			this.downloadLabel();
		},
		downloadXlsx: function (e) {
			// console.log($("select[name=category]").val());
			// console.log($("input[name=q]").val());
			// let countDate = this.dateDiff(this.filter.dt1, this.filter.dt2);
			this.downloading = true;
			var table = $('#tblriwayat').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
			this.loadingContent = true;
			authFetch("/report/order/arsip_excell", {
					method: "POST",
					body: data,
				})
				.then((response) => {
					if (response.status === 201) {
					} else if (response.status === 400) {
					} else if (response.status === 500){
						Swal.fire("Timeout!", ``, "error");
						this.loading2 = false;
						this.msg = '';
						return false;
					}

					return response.blob();
				})
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					this.loadingContent = false;
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "data.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
		reloadData: function (e) {
			this.table.api().ajax.reload();
		},
		updateFilter: function (e) {
			this.filter = e;
			this.table.api().ajax.reload();
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.datatype = this.data_type;
			this.table.api().ajax.reload();
		},
		orderType(event) {
			this.filter.order_type = event.target.value;
			this.table.api().ajax.reload();
		},
		onDate() {
			this.table.api().ajax.reload();
		},
		selectStatus: function () {
			this.show = !this.show;
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return fileName? require(`../assets/img/${fileName}.png`) : ''; // the module request
		},
		dateDiff(dt1, dt2){
			let d1 = new Date(dt1);
			let d2 = new Date(dt2);

			const difference = d2.getTime() - d1.getTime();
			const days = Math.round(difference / (1000 * 60 * 60 * 24));
			return days;
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/arsip/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				// return false;
			}
			else if (e.target.matches(".link-chat")) {
				openChat(e.target.dataset.to);
				return false;
			} 
			else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-order:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
					this.selectedResi = '';
				} else {
					this.$refs.checkall.checked = true;
					var selected = [];
					this.selectedResi = checkboxes[0].value.split(';')[2];
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked){
							var exp = checkboxes[i].value.split(';');
							this.courier = exp[1];
							console.log('all',exp);
							if(this.courier == 'JNE'){
								console.log('masuk');
							}
							console.log('keluar');
							console.log(this.courier);
							selected.push(exp[0]);
						}
					}
					this.selectedItems = selected;
				}
				this.selectedCount = checkboxes.length;
				if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
				else $(this.$refs.btnSelected).hide();
				return false;
			}
		},
		submitForm: function (ev) {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			var urlSubmit = "/users";
			if (this.method == "PUT") urlSubmit = "/users/" + this.form.id;

			authFetch(urlSubmit, {
					method: this.method,
					body: data,
				})
				.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}

						return;
					}
					this.table.api().ajax.reload();
					$(e.formDialog).modal("hide");
				});

			ev.preventDefault();
		},
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Riwayat Kiriman'
		});
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		
		var isShipper = this.userInfo.role_id > 10;
		var isKurir = this.userInfo.role_id == 9;
		var filterBy = [1, 2, 3, 4, 5, 6, 12, 10, 11];
		var role_id =  this.userInfo.role_id;
		if(isShipper)filterBy = [0, 1, 2, 3, 4, 5, 6, 11];
		this.table = createTable(e.tableuser, {
			title: "Data User",
			scrollX: true,
			autoWidth: false,
			roles: this.$route.params.roles,
			ajax: "/order/arsip_riwayat",
			frame: false,
			toolbarEl: e.toolbar,
			lengthChange: true,
			// filter: true,
			stateSave: true,
			dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: filterBy,
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			"order": [
				[7, 'desc'],
				[2, 'desc']
			],
			columns: [
				{
					data: "order_code",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${row.order_code}</span>`);
					},
				},
				{
					data: "billNo"
				},
				{
					data: "gudang",
					title: "Asal Gudang",
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "destName",
					visible: true,
					render: function (data, type, row, meta) {
						return (
							'<a class="link link-chat text-info" style="cursor:pointer;" data-to="' +
							row.destTel +
							'">' +
							data +
							"</a>"
						);
					},
				},
				{
					data: "destCity",
					render: function (data, type, row, meta) {
						return row.kecamatan + ", " + row.destCity;
					},
				},
				{
					data: "courierCode",
					visible: isKurir ? false : true,
					render: function (data, type, row, meta) {
						if (!data) return '';
						var logo = data.toLowerCase();
						return '<img class="d-block m-auto" height="16" src="' + self.getKurirLogo(logo) + '" /><small class="d-block text-center">' + (row.service_code ? row.service_code : '') + '</small>';
					},
				},
				{
					data: "pickupTime",
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "pickupStatus",
					render: function (data, type, row, meta) {
						var badge = '';
						badge = self.infoStatus(row);
						// if (row.cancel == 1)
						// 	badge = '<span class="badge badge-danger">Cancel</span>';
						// else if (row.pickupStatus == 0)
						// 	badge = '<span class="badge badge-inverse">DRAFT</span>';
						// else {
						// 	if (row.trackingStatus == 0)
						// 		badge = '<span class="badge badge-secondary">Menunggu Pickup</span>';
						// 	else if (row.trackingStatus == 1)
						// 		badge = '<span class="badge badge-warning">Proses</span>';
						// 	else if (row.trackingStatus == 2)
						// 		badge = '<span class="badge badge-success">Terkirim</span>';
						// 	else if (row.trackingStatus == 3)
						// 		badge = '<span class="badge badge-warning">Proses Retur</span>';
						// 	else if (row.trackingStatus == 4) {
						// 		if (row.retStatus == 1) 
						// 			badge = '<span class="badge badge-success">Retur Diterima</span>';
						// 		else if (!row.retStatus && !row.retClaim)
						// 			badge = '<span class="badge badge-info">Retur Diserahkan</span>';
						// 		else if (row.retClaim == 1)
						// 			badge = '<span class="badge badge-danger">Paket Diclaim</span>';
						// 		else if (row.retClaim == 2)
						// 			badge = '<span class="badge badge-success">Retur Claim disetujui</span>';
						// 		else if (row.retClaim == 3)
						// 			badge = '<span class="badge badge-danger">Retur Claim ditolak</span>';
						// 	} else if (row.trackingStatus == 5) {
						// 		badge = '<span class="badge badge-danger">Bermasalah</span>';
						// 		if (row.retClaim == 1)
						// 			badge = badge + ' <span class="badge badge-danger">Paket Diclaim</span>';
						// 		else if (row.retClaim == 2)
						// 			badge = badge + ' <span class="badge badge-success">Retur Claim disetujui</span>';
						// 		else if (row.retClaim == 3)
						// 			badge = badge + ' <span class="badge badge-danger">Retur Claim ditolak</span>';
						// 		if (row.retClaim != 0)
						// 			badge = badge + ' <span class="badge badge-info">' + row.retNote + '</span>';
						// 	}
						// }
						// if(row.updated == 1)
						// 	badge += ' <span class="badge badge-warning"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Updated</span>';
						return badge;
					},
				},
				{
					data: "ticket_status",
					render: function (data, type, row, meta) {
						if (data == 0) {
							return "";
						} else if (data == 1) {
							return '<span class="badge badge-info">Open</span>';
						} else if (data == 2) {
							return '<span class="badge badge-success">Closed</span>';
						} else if (data == 3) {
							return '<span class="badge badge-warning">Pending</span>';
						} else {
							return "";
						}
					},
				},
				{
					data: "shipperId",
					visible: false,
				},
				{
					data: "shipperName",
					visible: !isShipper,
					render: function (data, type, row, meta) {
						return (
							'<a class="link link-chat text-info" style="cursor:pointer;" data-to="' +
							row.senderPhone + '">' + data + "</a>"
						);
					},
				},
				{
					data: "service_code",
					visible: false,
				},
				{
					sortable: (role_id == 1 || role_id == 2 || role_id == 3 || role_id == 10  ? false : true),
					data: role_id == 1 || role_id == 2 || role_id == 3 || role_id == 10 || role_id == 6?"mark":"order_type",
					render: function (data, type, row, meta) {
						if (role_id == 1 || role_id == 2 || role_id == 3 || role_id == 10) {
							return data;
						}
						else{
							if (data == 0) {
								return '<span class="badge badge-info">Pickup</span>';
							} else if (data == 1) {
								return '<span class="badge badge-warning">Drop</span>';
							} else {
								return "";
							}
						}
					},
				},
			],
			createdRow: function (row, data, dataIndex) {
				if (data['special_claim'] == 1) {
					$(row).css('background-color', '#F7EBEB');
				}
			},
			rowCallback: function (row, data) {
				// $("td:eq(0)", row).html(
				// );
			},
			// drawCallback: function (row, data) {
			// 	self.$refs.checkall.checked = false;
			// },
			initComplete: function () {
				$(e.btnSelected).appendTo('.btn-area-selected');
				var column = self.table.api().column( 11 );
 
				// Toggle the visibility
				column.visible( !isShipper );
				$('.loading-overlay').removeClass('show');
			},
			buttonClick: (evt) => {
				if (evt.role == "create") {
					self.form = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Tambah User";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.form = evt.data;
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit User";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					if (evt.data.pickupStatus > 0) {
						Swal.fire({
							title: "Pemberitahuan",
							text: "Data Pesanan tidak bisa dihapus",
						});
						return;
					}

					Swal.fire({
						title: "Hapus data?",
						text: "Data pesanan akan dibatalkan",
						icon: "question",
						denyButtonText: '<i class="fa fa-times"></i> Hapus',
						showCancelButton: true,
						showDenyButton: true,
						showConfirmButton: false,
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isDenied) {
							authFetch("/order/" + evt.data.id, {
									method: "DELETE",
									body: "id=" + evt.data.id,
								})
								.then((res) => {
									return res.json();
								})
								.then((js) => {
									this.table.api().ajax.reload();
								});

							//Swal.fire('Hapus!', '', 'success')
						}
					});
				}
			},
		});


		$(e.formPrint).on("show.bs.modal", (e) => {
			if (!this.isOpen) {
				this.downloadLabel();
				this.isOpen = true;
			}
		});
		$(e.formPrintInvoice).on("show.bs.modal", (e) => {
			if (!this.isOpen) {
				this.downloadInvoice();
				this.isOpen = true;
			}
		});
		$(e.formCancelOrder).on("show.bs.modal", (e) => {
			if (!this.isOpen) {
				// this.downloadInvoice();
				this.isOpen = true;
			}
		});
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
</style>